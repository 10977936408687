import styled from 'styled-components';

export const MessageButton = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 2.995vw;
  color: #464855;
  letter-spacing: 0;
`;

export const ArrowImage = styled.img`
  margin-left: 2.474vw;
  width: 3.646vw;
  object-fit: contain;
`;

export const Container = styled.div`
  background-color: #F3F0EB;
  display: flex;
  padding: 7.422vw 0;
  flex-direction: column;
`;

export const BrideImage = styled.img`
  width: 78.78vw;
  height: 78.78vw;
`;

export const Heading = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  z-index: 1;
  margin: 0 30px;
`;

export const HeadingColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const ProfileName = styled.span`
  font-family: BigCaslon-Regular;
  font-size: 8.073vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 4.948vw;
  margin-bottom: 4.948vw;
  width: 60%;
`;

export const Location = styled.span`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 2.865vw;
  color: #343538;
  letter-spacing: 0.95px;
  line-height: 4.948vw;
  margin-bottom: 4.948vw;
`;

export const AboutTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 6.250vw;
  color: #343538;
  letter-spacing: 0.74px;
  line-height: 4.948vw;
`;

export const AboutSummary = styled.div`
  margin-top: 4.818vw;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 2.344vw;
  color: #000000;
  letter-spacing: 0;
  line-height: 4.557vw;
  width: 62.63vw;
  margin-bottom: 5.339vw;
`;

export const Body = styled.div`
  background-color: white;
  padding-top: 7.422vw;
`;

export const BodyTitle = styled.div`
  font-family: BigCaslon-Regular;
  font-size: 5.859vw;
  color: #464855;
  letter-spacing: 0.36px;
  text-align: center;
`;

export const ItemsContainer = styled.div`
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  > div:not(:first-child) {
    margin-top: 20px;
  }
`;

export const Item = styled.div`

`;

export const Divider = styled.div`
  height: 1px;
  background-color: #979797;
  margin: 0 7.813vw;
`;

export const TestimoniesContainer = styled.div`
  margin-top: 4.375vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5.750vw;
  margin-left: calc(3.500vw / 2);
  margin-right: calc(3.500vw / 2);
`;

export const TestimoniesTitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-size: 4.948vw;
  color: #212224;
  letter-spacing: 0.95px;
  text-align: center;
  line-height: 5.990vw;
`;

export const TestimoniesSubtitle = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-size: 2.474vw;
  color: #A3A5AD;
  letter-spacing: 0;
  text-align: center;
  line-height: 3.776vw;
  width: 62.76vw;
  margin-top: 1.042vw;
`;

export const Testimonies = styled.div`
  display: flex;
  flex-direction: column;
`;