import React from 'react';
import Item from '../../Item';
import {
  Container, Heading, HeadingColumn, ProfileName, Location,
  AboutTitle, AboutSummary, Body, BodyTitle, ItemsContainer,
  BrideImage,MessageButton, ArrowImage,
} from './styled';
import Arrow from './Arrow.svg';

function Profile({
  listings, sellerProfile, sendMessage,
}) {
  const {
    details: { name } = {},
    profile: { aboutMe, avatar } = {},
    address: { city, state } = {},
    sellerId, __id,
  } = sellerProfile || {};
  return (
    <Container>
      <Heading>
        <AboutTitle style={{
          position: 'absolute', right: 0, top: 0,
        }}>#{sellerId}</AboutTitle>
        <HeadingColumn>
          <ProfileName>{name}</ProfileName>
          <Location>{city}, {state}</Location>
          <BrideImage src={avatar}></BrideImage>
        </HeadingColumn>
        <HeadingColumn style={{ marginLeft: '3.938vw' }}>
          <AboutTitle>About</AboutTitle>
          <AboutSummary>{aboutMe}</AboutSummary>
          <div style={{
            display: 'flex', cursor: 'pointer', marginTop: '2.563vw',
          }}>
            <MessageButton onClick={() => sendMessage(__id)}>SEND A MESSAGE</MessageButton>
            <ArrowImage src={Arrow} />
          </div>
        </HeadingColumn>
      </Heading>
      <Body>
        <BodyTitle>Listed Gowns:</BodyTitle>
        <ItemsContainer>
          {
            listings.map((item, index) => (<Item {...item} key={index}></Item>))
          }
        </ItemsContainer>
        {/* <Divider />
                <TestimoniesContainer>
          <TestimoniesTitle>What Our Brides Say</TestimoniesTitle>
          <TestimoniesSubtitle>Bride To Be was a pleasure to work with! They were friendly and responded to my questions so quickly!</TestimoniesSubtitle>
          <Testimonies>
            {
              testimonies.map((testimony, index) => (<Testimony {...testimony} key={index} />))
            }
          </Testimonies>
        </TestimoniesContainer> */}
      </Body>
    </Container>
  );
}

export default Profile;
