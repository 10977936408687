import React, {
  useState, useEffect, useCallback,
} from 'react';
import { connect } from 'react-redux';
//components
import Layout from '../components/Layout';
import ProfileMobile from '../components/Mobile/Profile';
import ProfileDesktop from '../components/Desktop/Profile';
//styles
import './style.css';
import { getCollection } from '../helpers/Firebase';
import { getCurrentPage } from '../helpers/Utils';
import { setMetaTags } from '../actions/homeActions';
import { showMessageModal } from '../actions/itemsActions';

function Profile({
  products, accountData, setMetaTags, showMessageModal,
}) {
  const [sellerListings, setSellerListings] = useState([]);
  const [sellerProfile, setSellerProfile] = useState({});

  const getSellerListings = useCallback(async () => {
    const sellerId = getCurrentPage(true).split('/')[2];
    const _sellers = await getCollection(`sellers`, ['sellerId', '==', sellerId]);
    const _seller = _sellers[0];
    setSellerProfile(_seller);
    const _sellerListings = Object.values(products).filter(({ seller })=> _seller.__id === seller);
    setSellerListings(_sellerListings);
  }, [products]);

  useEffect(() => {
    if (products) {
      getSellerListings();
    }
  }, [getSellerListings, products]);

  useEffect(() => {
    if (sellerProfile) {
      const {
        aboutMe, profile: {
          avatar, firstName, lastName,
        } = {},
      } = sellerProfile || {};
      const description = aboutMe;
      const image = avatar;
      const url = window.location.href;
      const title = `${firstName} ${lastName}`;

      setMetaTags({
        description, image, url, title,
      });
    }
  }, [sellerProfile, setMetaTags]);

  return (
    <Layout
      mobile={<ProfileMobile sendMessage={showMessageModal} accountData={accountData} sellerProfile={sellerProfile} listings={sellerListings}/>}
      desktop={<ProfileDesktop sendMessage={showMessageModal} accountData={accountData} sellerProfile={sellerProfile} listings={sellerListings} />} />
  );
}

const mapStateToProps = (state) => ({ ...state.shopReducer, ...state.userReducer });
const mapDispatchToProps = { setMetaTags, showMessageModal };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);